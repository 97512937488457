export default [
  {
    group: "/",
    model: false,
    icon: "mdi-home-outline",
    title: "Dashboard",
    to: "/",
    permission: [
      ["read", "All"],
      ["read", "Superadmin"],
    ],
    testid: "link-sidebar-home",
  },
  {
    group: "/store",
    model: false,
    icon: "mdi-store",
    title: "Store",
    permission: [
      ["read", "Superadmin"],
      ["read", "licenses"],
    ],
    paths: [
      "store-bundles-list",
      "store-bundle-create",
      "store-bundle-edit",
      "store-bundles-registered-list",
      "store-bundle-registered-create",
      "store-bundle-registered-edit",
      "store-events-list",
      "store-bundle-event-create-settings",
      "store-bundle-event-edit-settings",
      "store-bundle-event-edit-connections",
      "store-bundles-client-list",
      "store-bundle-client-buy",
      "store-deals-list",
      "store-deal-create",
      "store-licenses-list",
      "store-license-create",
      "store-event-control",
      "store-events-list",
      "engine-locations-list",
      "engine-location-create",
      "engine-location-edit",
      "event-members-roles-list",
      "event-member-role-create",
      "event-member-role-edit",
    ],
    testid: "link-sidebar-store-category",
    children: [
      // {
      //   icon: 'mdi-book-open',
      //   title: 'Bundles',
      //   to: 'bundles-list',
      //   permission: [['read', 'Superadmin']],
      //   paths: ['store-bundles-list', 'store-bundle-create', 'store-bundle-edit'],
      // },
      // {
      //   icon: 'mdi-book-open',
      //   title: 'Bundles',
      //   to: 'bundles',
      //   permission: [['read', 'registry']],
      //   excludePermission: [['read', 'Superadmin']],
      //   paths: ['store-bundles-client-list', 'store-bundle-client-buy'],
      // },
      // {
      //   icon: 'mdi-book-open',
      //   title: 'Registry',
      //   to: 'bundles/registry',
      //   permission: [['read', 'Superadmin']],
      //   paths: ['store-bundles-registered-list', 'store-bundle-registered-create', 'store-bundle-registered-edit'],
      // },
      {
        icon: "mdi-book-open",
        title: "Deals",
        to: "deals",
        permission: [
          ["read", "Superadmin"],
          ["read", "deals"],
        ],
        paths: ["store-deals-list", "store-deal-create"],
        testid: "link-sidebar-deals",
      },
      // @todo remove after implementation of new design
      {
        icon: "mdi-book-open",
        title: "Events",
        to: "events",
        permission: [
          ["read", "Superadmin"],
          ["read", "events"],
        ],
        paths: [
          "store-events-list",
          "store-bundle-event-create-settings",
          "store-bundle-event-edit-settings",
          "store-bundle-event-edit-connections",
          "store-event-control",
        ],
        testid: "link-sidebar-events",
      },
      {
        icon: "mdi-book-open",
        title: "Event roles",
        to: "event/roles",
        permission: [["read", "Superadmin"]],
        paths: [
          "event-members-roles-list",
          "event-member-role-create",
          "event-member-role-edit",
        ],
        testid: "link-sidebar-event-roles",
      },
      {
        icon: "mdi-book-open",
        title: "Locations",
        to: "locations",
        permission: [
          ["read", "Superadmin"],
          ["read", "engines_locations"],
        ],
        paths: [
          "engine-locations-list",
          "engine-location-create",
          "engine-location-edit",
        ],
        testid: "link-sidebar-locations",
      },
      {
        icon: "mdi-book-open",
        title: "Licenses",
        to: "licenses",
        permission: [
          ["read", "Superadmin"],
          ["read", "licenses"],
        ],
        paths: ["store-licenses-list", "store-license-create"],
        testid: "link-sidebar-licenses",
      },

      // {
      //   icon: 'mdi-cart-outline',
      //   title: 'Store',
      //   to: 'store',
      //   permission: [['read', 'Superadmin']],
      // },
      // {
      //   icon: 'mdi-folder-account',
      //   title: 'Billing',
      //   to: 'billing',
      //   permission: [['read', 'Superadmin']],
      // }
    ],
  },
  {
    group: "/company",
    model: false,
    icon: "mdi-view-dashboard",
    title: "Company",
    permission: [
      ["read", "users"],
      ["update", "groups"],
      ["update", "roles"],
      ["read", "users_invites"],
      ["update", "Superadmin"],
    ],
    paths: [
      "company_profile",
      "company_users",
      "company_groups",
      "company_invites",
      "company_roles",
      "company_role_create",
      "company_role_edit",
    ],
    testid: "link-sidebar-company-category",
    children: [
      {
        icon: "mdi-contacts",
        title: "Company profile",
        to: "profile",
        permission: [
          ["read", "groups"],
          ["read", "Superadmin"],
        ],
        paths: ["company_profile"],
        testid: "link-sidebar-company-profile",
      },
      {
        icon: "mdi-account-multiple",
        title: "Users",
        to: "users",
        permission: [
          ["read", "users"],
          ["read", "Superadmin"],
        ],
        paths: ["company_users"],
        testid: "link-sidebar-users",
      },
      {
        icon: "mdi-view-dashboard",
        title: "Companies list",
        to: "list",
        permission: [
          ["update", "groups"],
          ["update", "Superadmin"],
        ],
        paths: ["company_groups"],
        testid: "link-sidebar-companies",
      },
      {
        icon: "mdi-view-dashboard",
        title: "Roles",
        to: "roles",
        permission: [
          ["update", "roles"],
          ["update", "Superadmin"],
        ],
        paths: ["company_roles", "company_role_create", "company_role_edit"],
        testid: "link-sidebar-roles",
      },
      {
        icon: "mdi-view-dashboard",
        title: "Invites",
        to: "invites",
        permission: [
          ["read", "users_invites"],
          ["read", "Superadmin"],
        ],
        paths: ["company_invites"],
        testid: "link-sidebar-invites",
      },
    ],
  },
  {
    group: "/engines",
    model: false,
    icon: "mdi-laptop",
    title: "Engine",
    paths: [
      "engines_list",
      "engine_rc_connection",
      "active-engines-rc-connections",
      "history-engines-rc-connections",
      "root_kiosk_nj",
      "root_kiosk_rogers",
      "engines_webengine",
      "engines_webengine_create",
      "engines_webengine_edit",
      "engines_webengine_streaming",
      "engines_speed_test",
      "engines_speed_test_create",
      "engines_speed_test_history",
      "engines_speed_test_edit",
      "engines_speed_test_run",
      "engines_speed_test_hash",
      "engine-streams-list",
      "engine-streams-list-active",
      "engine-streams-list-history",
      "engines_unattended",
    ],
    permission: [
      ["read", "engines"],
      ["read", "web_engines"],
      ["rc.playlist", "ws_rc"],
      ["read", "un_connections"],
      ["read", "engine_presets"],
      ["read", "debug_speed_test"],
      ["read", "Superadmin"],
      ["read", "un_list"],
    ],
    testid: "link-sidebar-engines-category",
    children: [
      {
        icon: "mdi-monitor-multiple",
        title: "Engines list",
        to: "list",
        permission: [
          ["read", "engines"],
          ["read", "Superadmin"],
        ],
        paths: ["engines_list"],
        testid: "link-sidebar-engines",
      },
      {
        icon: "mdi-play-protected-content",
        title: "Remote Control",
        to: "remote-control/active",
        permission: [
          ["rc.playlist", "ws_rc"],
          ["read", "Superadmin"],
        ],
        paths: [
          "engine_rc_connection",
          "root_kiosk_nj",
          "root_kiosk_rogers",
          "active-engines-rc-connections",
          "history-engines-rc-connections",
        ],
        testid: "link-sidebar-remote-control",
      },
      // {
      //   icon: "collections",
      //   title: "Presets",
      //   to: "presets",
      //   permission: [
      //     ["read", "engine_presets"],
      //     ["read", "Superadmin"],
      //   ],
      // },
      {
        icon: "camera_enhance",
        title: "Webengines",
        to: "webengines",
        permission: [
          ["read", "web_engines"],
          ["read", "Superadmin"],
        ],
        paths: [
          "engines_webengine",
          "engines_webengine_create",
          "engines_webengine_edit",
          "engines_webengine_streaming",
        ],
        testid: "link-sidebar-webengines",
      },
      {
        icon: "mdi-speedometer",
        title: "Speed test",
        to: "speed-test",
        permission: [
          ["read", "debug_speed_test"],
          ["read", "Superadmin"],
        ],
        paths: [
          "engines_speed_test",
          "engines_speed_test_create",
          "engines_speed_test_history",
          "engines_speed_test_edit",
          "engines_speed_test_run",
          "engines_speed_test_hash",
        ],
        testid: "link-sidebar-speed-test",
      },
      {
        icon: "mdi-monitor-multiple",
        title: "Streams",
        to: "streams/active",
        paths: [
          "engine-streams-list",
          "engine-streams-list-active",
          "engine-streams-list-history",
        ],
        permission: [["read", "Superadmin"]],
        testid: "link-sidebar-streams",
      },
      {
        icon: "mdi-remote-desktop",
        title: "Unattended",
        to: "unattended",
        permission: [
          ["read", "un_list"],
          ["read", "Superadmin"],
        ],
        paths: ["engines_unattended"],
        testid: "link-sidebar-unattended",
      },
    ],
  },
  {
    group: "/settings",
    model: false,
    icon: "settings",
    title: "Settings",
    permission: [
      ["read", "settings_multi"],
      ["read", "app_version_message"],
      ["read", "pages"],
      ["read", "Superadmin"],
    ],
    paths: [
      "signal_servers",
      "turn_servers",
      "janus_servers",
      "asp_version_messages",
      "pages-list",
      "page-create",
      "page-edit",
    ],
    testid: "link-sidebar-settings-category",
    children: [
      {
        icon: "call_split",
        title: "Signal servers",
        to: "signal-servers",
        permission: [
          ["read", "settings_multi"],
          ["read", "Superadmin"],
        ],
        paths: ["signal_servers"],
        testid: "link-sidebar-signal-servers",
      },
      {
        icon: "mdi-axis-z-rotate-clockwise",
        title: "Turn servers",
        to: "turn-servers",
        permission: [
          ["read", "settings_multi"],
          ["read", "Superadmin"],
        ],
        paths: ["turn_servers"],
        testid: "link-sidebar-turn-servers",
      },
      {
        icon: "mdi-animation-play",
        title: "Janus servers",
        to: "janus-servers",
        permission: [
          ["read", "settings_multi"],
          ["read", "Superadmin"],
        ],
        paths: ["janus_servers"],
        testid: "link-sidebar-janus-servers",
      },
      {
        icon: "mdi-message-settings",
        title: "Version messages",
        to: "version-messages",
        permission: [
          ["read", "app_version_message"],
          ["read", "Superadmin"],
        ],
        paths: ["asp_version_messages"],
        testid: "link-sidebar-version-messages",
      },
      {
        icon: "mdi-message-settings",
        title: "Pages",
        to: "pages",
        permission: [
          ["read", "pages"],
          ["read", "Superadmin"],
        ],
        paths: ["pages-list", "page-create", "page-edit"],
        testid: "link-sidebar-pages",
      },
    ],
  },
  {
    group: "/debug",
    model: false,
    icon: "mdi-decagram",
    title: "Debug",
    permission: [
      ["read", "Superadmin"],
      ["read", "debug_turns_status"],
    ],
    paths: [
      "debug-signal-servers",
      "debug-turn-servers",
      "debug-janus-servers",
    ],
    testid: "link-sidebar-debug-category",
    children: [
      {
        icon: "call_split",
        title: "Signal servers",
        to: "signal-servers",
        // @todo must resolve for permissions for this endpoints that do not have endpoint in backend
        permission: [
          ["read", "debug_turns_status"],
          ["read", "Superadmin"],
        ],
        paths: ["debug-signal-servers"],
        testid: "link-sidebar-debug-signals",
      },
      {
        icon: "mdi-axis-z-rotate-clockwise",
        title: "Turn servers",
        to: "turn-servers",
        // @todo must resolve for permissions for this endpoints that do not have endpoint in backend
        permission: [
          ["read", "debug_turns_status"],
          ["read", "Superadmin"],
        ],
        paths: ["debug-turn-servers"],
        testid: "link-sidebar-debug-turns",
      },
      {
        icon: "mdi-axis-z-rotate-clockwise",
        title: "Janus servers",
        to: "janus-servers",
        // @todo must resolve for permissions for this endpoints that do not have endpoint in backend
        permission: [
          ["read", "debug_turns_status"],
          ["read", "Superadmin"],
        ],
        paths: ["debug-janus-servers"],
        testid: "link-sidebar-debug-januses",
      },
    ],
  },
  {
    group: "/support",
    model: false,
    icon: "mdi-help",
    title: "Support",
    permission: [
      ["read", "All"],
      ["read", "Superadmin"],
    ],
    paths: ["downloads-page"],
    testid: "link-sidebar-support-category",
    children: [
      {
        icon: "mdi-arrow-down-bold-circle-outline",
        title: "Downloads",
        to: "downloads",
        permission: [
          ["read", "All"],
          ["read", "Superadmin"],
        ],
        paths: ["downloads-page"],
        testid: "link-sidebar-downloads",
      },
      {
        icon: "mdi-open-in-new",
        title: "Support portal",
        href: "store_support_link",
        permission: [
          ["read", "All"],
          ["read", "Superadmin"],
        ],
        testid: "link-sidebar-support-portal",
      },
    ],
  },
]

//
// {
//   icon: 'add_to_queue',
//   title: 'Register requests',
//   to: 'register-requests',
// },
// {
//   icon: 'view_stream',
//   title: 'Streams',
//   to: 'streams',
// },
// {
//   icon: 'transform',
//   title: 'Ping turns',
//   to: 'ping-turns',
// },
//
// {
//   name: 'Store',
//   icon: 'shopping_cart',
//   childs: [
//       {
//         path: '/store/events',
//         name: 'Events',
//         icon: 'event',
//       },
//       {
//         path: '/store/packages',
//         name: 'Packages',
//         icon: 'account_balance',
//       },
//       {
//         path: '/store/billing',
//         name: 'Billing',
//         icon: 'account_balance_wallet',
//       },
//   ],
// },
// {
//   name: 'Support',
//   icon: 'contact_support',
//   childs: [
//       {
//         path: '/support/help',
//         name: 'Help',
//         icon: 'help_outline',
//       },
//       {
//         path: '/support/faq',
//         name: 'FAQ',
//         icon: 'fab fa-yelp',
//       },
//       {
//         path: '/support/blog',
//         name: 'Blog',
//         icon: 'fab fa-blogger',
//       },
//       {
//         path: '/support/ask-question',
//         name: 'Ask question',
//         icon: 'live_help',
//       },
//   ],
// },
