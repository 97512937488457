<template>
  <v-app-bar app clipped-left clipped-right color="black" dark>
    <v-toolbar-title class="align-center d-flex" v-if="isAuthenticated">
      <span class="logo-icon" @click="toHome"></span>
      <span
        class="logo-text ml-2"
        :class="`${showLogo ? '' : 'hidelogo'}`"
        @click="toHome"
        v-if="$vuetify.breakpoint.smAndUp"
        data-testid="link-home"
      ></span>
    </v-toolbar-title>
    <!---/Toggle sidebar part -->
    <div v-if="isAuthenticated && $vuetify.breakpoint.width <= 1090">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.width <= 1090
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
        data-testid="btn-expand-collapse-sidebar"
      />
    </div>
    <!---/Toggle sidebar part -->

    <v-spacer />
    <!---right part -->

    <!---User -->
    <v-menu
      v-if="isAuthenticated"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      data-testid="menu-user-popup"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1" data-testid="btn-user-avatar">
          <v-avatar size="40" data-testid="avatar-user">
            <!-- <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" /> -->
            <v-icon dark>account_circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-content data-testid="user-email">
            {{ getCurrentUser.email }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item to="/profile" data-testid="btn-user-profile">
          <v-list-item-content>Profile</v-list-item-content>
          <v-list-item-icon class="ml-2">
            <v-icon>account_box</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item to="/logout" data-testid="btn-logout">
          <v-list-item-content>Logout</v-list-item-content>
          <v-list-item-icon class="ml-2">
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations, mapGetters } from "vuex"
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    href() {
      return undefined
    },
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
    ...mapGetters({
      getCurrentUser: "currentUser",
      isAuthenticated: "isAuthenticated",
    }),
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    searchbox() {
      this.showSearch = !this.showSearch
    },
    toHome() {
      this.$router.push("/").catch((err) => {})
    },
  },
}
</script>

<style lang="sass" scoped>
.v-application #main-sidebar.theme--dark.white
  background: #363636!important

.searchinput
  position: absolute
  width: 100%
  margin: 0
  left: 0
  z-index: 10
  padding: 0 15px
.descpart
  max-width: 220px
.logo-icon, .logo-text
  background-repeat: no-repeat
  background-size: contain
  background-position: center
  height: 40px
  line-height: 40px
  display: inline-block
  vertical-align: middle
.logo-icon
  width: 62px
  background-image: url('../../assets/img/header-logo-icon.png')
  cursor: pointer
.logo-text
  width: 100px
  background-image: url('../../assets/img/header-logo-text.png')
  cursor: pointer
  &.hidelogo
    display: none
</style>
