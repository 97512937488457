import { mapGetters } from "vuex"
export default {
  data() {
    return {}
  },
  // created: function () {
  //   let app = this;
  //   app.getUserGroups();
  // },
  methods: {
    getUserGroups: function () {
      let app = this
      if (app.$can("read", "groups")) {
        app.$store.dispatch("getUserGroups")
      }
    },
    // @todo because of server pagination need to make request
    getCompanyName(id) {
      let _company = this.getUserGroupsList.filter(
        (company) => company._key === id
      )
      return _company?.[0]?.name || ""
    },
  },
  computed: {
    ...mapGetters({
      getUserGroupsList: "getUserGroupsList",
      getUserGroupsCount: "getUserGroupsCount",
    }),
  },
}
