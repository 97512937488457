<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="1090"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    app
    id="main-sidebar"
    v-bar
    data-testid="container-sidebar"
  >
    <!--    :mini-variant.sync="sidebarMini"-->
    <!---Group Area -->
    <v-card>
      <v-img
        src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="80px"
        data-testid="container-sidebar-company-info"
      >
        <div class="pa-4" style="position: relative">
          <div
            class="body-1"
            v-if="!sidebarMini"
            data-testid="sidebar-company-name"
          >
            {{ companyInfo.name }}
          </div>
          <div class="caption" data-testid="sidebar-user-role-name">
            {{ currUser.role_name }}
          </div>

          <!--          <v-btn-->
          <!--            v-if="!sidebarMini && $vuetify.breakpoint.name === 'md'"-->
          <!--            icon-->
          <!--            @click.stop="$store.dispatch('setMiniSidebar', true)"-->
          <!--            absolute-->
          <!--            bottom-->
          <!--            right-->
          <!--          >-->
          <!--            <v-icon>mdi-chevron-left</v-icon>-->
          <!--          </v-btn>-->
          <!--          <v-btn-->
          <!--            v-if="sidebarMini && $vuetify.breakpoint.name === 'md'"-->
          <!--            icon-->
          <!--            @click.stop="$store.dispatch('setMiniSidebar', false)"-->
          <!--            absolute-->
          <!--            bottom-->
          <!--            right-->
          <!--          >-->
          <!--            <v-icon>mdi-chevron-right</v-icon>-->
          <!--          </v-btn>-->
        </div>
      </v-img>
    </v-card>
    <!---Group Area -->
    <v-list expand nav class="mt-1" data-testid="container-sidebar-navigation">
      <template v-for="(item, i) in items">
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col cols="12">
            <v-subheader
              v-if="item.header"
              class="d-block text-truncate"
              :data-testid="getTestId(item, i)"
            >
              {{ item.header }}
            </v-subheader>
          </v-col>
        </v-row>
        <BaseItemGroup
          v-else-if="item.children && checkPermission(item)"
          :key="`group-${i}`"
          :item="item"
          :testid="getTestId(item, i)"
        ></BaseItemGroup>
        <BaseItem
          v-else-if="!item.children && checkPermission(item)"
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>
    <div class="version">version 2.9.0</div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import SidebarItems from "./SidebarItems"
import CompanyMixin from "@/mixins/group-mixin.js"
import BaseItemGroup from "../commonComponents/BaseItemGroup"
import BaseItem from "../commonComponents/BaseItem"

export default {
  name: "Sidebar",

  components: {
    BaseItemGroup,
    BaseItem,
  },

  mixins: [CompanyMixin],

  data: () => ({
    items: SidebarItems,
    mini: true,
  }),

  mounted() {
    if (this.$vuetify.breakpoint.name === "md") {
      this.$store.dispatch("setMiniSidebar", true)
    }
  },

  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    ...mapGetters({
      currUser: "currentUser",
      companyInfo: "getCurrentUserCompanyInfo",
      sidebarMini: "sidebarMini",
    }),
    Sidebar_drawer: {
      get() {
        return this.$store.state.appSettings.Sidebar_drawer
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val)
      },
    },
  },

  methods: {
    checkPermission: function (items) {
      let permission = false
      for (let item of items.permission) {
        if (this.$can(item[0], item[1])) permission = true
      }
      if (
        Object.prototype.hasOwnProperty.call(items, "excludePermission") &&
        items.excludePermission.length > 0
      ) {
        for (let item of items.excludePermission) {
          if (this.$can(item[0], item[1])) permission = false
        }
      }
      return permission
    },
    getTestId(item, i) {
      return Object.prototype.hasOwnProperty.call(item, "testid")
        ? item.testid
        : `test-id-${i}`
    },
  },
}
</script>

<style lang="scss" scoped>
.version {
  position: absolute;
  bottom: 10px;
  left: 16px;
  font-size: 10px;
  opacity: 0.6;
}

#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .v-navigation-drawer__border {
    display: none;
  }

  .v-list {
    padding: 8px 15px;
  }

  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
}
</style>
