<template>
  <v-app id="layoutDefault">
    <Header v-model="expandOnHover"></Header>
    <v-main>
      <v-container fluid class="page-wrapper pa-0">
        <router-view />
      </v-container>
    </v-main>

    <Sidebar v-if="isAuthenticated"></Sidebar>
    <v-overlay :value="showLoader" key="dialog-loader" z-index="500">
      <v-progress-circular indeterminate color="grey darken-3">
      </v-progress-circular>
    </v-overlay>

    <TermsPolicyDialog />
  </v-app>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex"
import Header from "@/components/header/Header"
import Sidebar from "@/components/sidebar/Sidebar"
import GeneralMixin from "@/mixins/general.js"
import TermsPolicyDialog from "@/components/commonComponents/TermsPolicyDialog"

export default {
  name: `LayoutDefault`,

  components: {
    Header,
    Sidebar,
    TermsPolicyDialog,
  },

  mixins: [GeneralMixin],

  props: {
    source: String,
  },

  data: () => ({
    expandOnHover: false,
  }),

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
  },

  computed: {
    ...mapState(["Customizer_drawer"]),
    ...mapGetters({
      showLoader: "getShowLoader",
      currentUser: "currentUser",
      userRole: "userRole",
      isAuthenticated: "isAuthenticated",
    }),
  },
}
</script>

<style lang="sass" scoped>
.link-logout .v-list__tile__avatar
  min-width: 0px

.link-menu-user-profile
    cursor: pointer

.dialog-loader-container
    background-color: #757575
    opacity: 0.5

.dialog-loader
  z-index: 500 !important
</style>
