<template>
  <v-dialog persistent v-model="showDialogParam" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Terms and Privacy ARHT Media</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-form data-vv-scope="form-terms-privacy">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="checkedTerms">
                <template v-slot:label>
                  <div>
                    I have read and accept
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          :href="termsFileUrl"
                          @click.stop
                          v-on="on"
                        >
                          Terms of Service
                        </a>
                      </template>
                      Download file
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="checkedPrivacy">
                <template v-slot:label>
                  <div>
                    I have read and accept
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          :href="privacyFileUrl"
                          @click.stop
                          v-on="on"
                        >
                          Privacy Policy
                        </a>
                      </template>
                      Download file
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!checkedTerms || !checkedPrivacy"
          color="success"
          text
          @click="continueTermsPrivacy()"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  data() {
    return {
      checkedTerms: false,
      checkedPrivacy: false,
      termsFileUrl: `${window.location.origin}/downloads/Terms_of_Service_ARHT_Media_FINAL_04.30.2021.docx`,
      privacyFileUrl: `${window.location.origin}/downloads/Privacy_Policy_ARHT_Media_FINAL_04.30.2021.docx`,
    }
  },

  methods: {
    continueTermsPrivacy() {
      let self = this
      self.$validator.validateAll("form-terms-privacy").then((result) => {
        if (result) {
          self.$store
            .dispatch("acceptTermsPrivacy")
            .then(() => {
              //do something
            })
            .catch((err) => {
              console.error("Error on continueTermsPrivacy", err)
            })
        }
      })
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
    showDialogParam() {
      return !_.isEmpty(this.currentUser) && !this.currentUser.terms_privacy
    },
  },
}
</script>
